import React from "react";
import { Link } from "react-router-dom";

export default function LinksAndNext({ curIndex, setCurIndex }) {
  return (
    <div className="lg:flex items-start justify-end space-x-6 flex-1 px-8 py-4 w-full z-0 hidden">
      <Link
        className="text-white hover:text-gray-100 transition-colors leading-none text-sm"
        to="/buyAds"
      >
        Buy TikTok Ads
      </Link>
      {/* <button
        className="text-white hover:text-gray-100 transition-colors leading-none text-sm"
        onClick={(e) => {
          setCurIndex(2);
        }}
      >
        Become a Torium
      </button> */}
    </div>
  );
}
