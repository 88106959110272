import React from "react";

export default function AdPlanDetailBox({ title, value }) {
  return (
    <div className="bg-white mt-2 rounded-xl py-2 px-3 flex items-center leading-none justify-between w-full">
      <p className="text-gray-500 text-xs">{title}</p>
      <p class="text-right text-sm font-semibold">{value}</p>
    </div>
  );
}
