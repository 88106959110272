import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/customer/PageHeader";
import ParagraphCaption from "../../components/customer/ParagraphCaption";
import ParagraphTitle from "../../components/customer/ParagraphTitle";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Privacy | Torium";
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen h-full w-full relative">
      <PageHeader title="Privacy Policy" />
      <div className="max-w-sm w-full pt-20 h-full mx-auto flex flex-col items-start justify-start px-4">
        <ParagraphTitle title="1. Introduction" />
        <p className="text-gray-800 leading-tight text-xs mb-1">
          This Privacy Policy describes the personal data we collect, how it’s
          used and shared, and your choices regarding this data.
        </p>
        <ParagraphTitle title="2. Data collection" />
        <ParagraphCaption caption="Data controller" />
        <p className="text-gray-800 leading-tight text-xs mb-1">
          Torium independently manages the personal data collected in connection
          with use of the Torium Platform.
        </p>

        <ParagraphCaption caption="Demographic data" />
        <p className="text-gray-800 leading-tight text-xs mb-1">
          We may collect demographic data about users, including through user
          surveys.
        </p>

        <ParagraphTitle title="7. Updates to this notice" />
        <p className="text-gray-800 leading-tight text-xs ">
          We may occasionally update this privacy policy. If we make significant
          changes, we will notify you through email. Use of Torium after an
          update to the Privacy Policy is equivalent to agreement.
        </p>
        <Link
          className="text-white text-center mt-2 py-2 px-4 bg-black hover:bg-gray-800 transition-colors w-full rounded-lg text-xs"
          to="/"
        >
          Back to home
        </Link>
        <p className="mx-auto text-center text-xs leading-tight mt-2 mb-6">
          You can contact us at{" "}
          <a
            href="mailto:info@torium.ca"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
          >
            info@torium.ca
          </a>{" "}
          or
          <br />
          <a
            href="https://twitter.com/T0R1UM"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
          >
            {" "}
            @T0R1UM on Twitter
          </a>
        </p>
      </div>
    </div>
  );
}
