import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import Background from "../../components/customer/Background";
import FeatureSummary from "../../components/customer/FeatureSummary";
import Footer from "../../components/customer/Footer";
import LinksAndNext from "../../components/customer/LinksAndNext";
import SliderBar from "../../components/customer/SliderBar";

export default function Landing(props) {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      10000 // every 5 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  const valuePropAndCaptionDict = [
    {
      title: "Films",
      caption: "Making future classics.",
    },
    // {
    //   title: "Render farm",
    //   caption:
    //     "Creating a low-cost, zero-emission render farm for 3D software like Blender and Maya.",
    // },
    {
      title: "TikTok ads",
      caption: "Creating relatable ads on the most popular social media app.",
    },
  ];

  return (
    <>
      <Background />
      <div className="flex flex-col lg:flex-row items-start justify-start w-screen h-screen z-10 ">
        <div className="flex flex-col items-start justify-start z-10 h-full flex-1 flex-grow">
          <FeatureSummary
            index={index}
            setIndex={setIndex}
            valuePropAndCaptionDict={valuePropAndCaptionDict}
          />
          <SliderBar
            curIndex={index}
            setCurIndex={setIndex}
            valuePropAndCaptionDict={valuePropAndCaptionDict}
          />
        </div>
        <div className="flex flex-col items-start justify-start z-10 lg:h-full">
          <LinksAndNext
            curIndex={index}
            setCurIndex={setIndex}
            valuePropAndCaptionDict={valuePropAndCaptionDict}
          />
          <Footer
            curIndex={index}
            setCurIndex={setIndex}
            valuePropAndCaptionDict={valuePropAndCaptionDict}
            props={props}
          />
        </div>
      </div>
    </>
  );
}
