import React from "react";

export default function ParagraphCaption({ caption, isFirst }) {
  return (
    <h6
      className={`font-semibold text-black leading-tight text-xs w-full ${
        !isFirst ? "mt-1" : ""
      } mb-1`}
    >
      {caption}
    </h6>
  );
}
