import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdPlan from "./pages/customer/AdPlan";
import BuyAds from "./pages/customer/BuyAds";
import Landing from "./pages/customer/Landing";
import PrivacyPolicy from "./pages/customer/PrivacyPolicy";
import Terms from "./pages/customer/Terms";
// import Phase1Overview from "./pages/customer/Phase1Overview";

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_51KrSPeKbfKkJA5iHsmAsOSlI7OYLUGfNOqj2YH2ongvOTv896ouSJyl3V2AfAZAUg92d3yNUImvoQqzjbWpgkKCb00082dPe5W"
    : "pk_test_51KrSPeKbfKkJA5iHOkXvMPkG4842sfFKkd4Sz7O3CYcjlHEDtXlSfyA5lFKTnVjdAWh3zgn7ln5wJ6iklEgUEYEL00ucsHbMUI"
);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/buyAds" element={<BuyAds />} />
          <Route exact path="/adPlan/:adPlanId" element={<AdPlan />} />
          {/* <Route exact path="/phase1" element={<Phase1Overview />} /> */}
          {/* <Route exact path="/phase1/storyboards" element={<Phase1Storyboards />} /> */}

          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/terms" element={<Terms />} />
        </Routes>
      </BrowserRouter>
    </Elements>
  );
}

export default App;
