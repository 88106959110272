import React from "react";

export default function SliderBar({
  curIndex,
  setCurIndex,
  valuePropAndCaptionDict,
}) {
  return (
    <div className="backdrop-blur-md bg-white w-full lg:px-8 px-4 flex items-center justify-start space-x-3 h-14 lg:h-24">
      {valuePropAndCaptionDict &&
        valuePropAndCaptionDict.length > 0 &&
        valuePropAndCaptionDict.map((curValProp, index) => (
          <button
            className={`${
              index === curIndex % valuePropAndCaptionDict.length
                ? "w-10"
                : "w-5"
            } focus:outline-none hover:w-10 transition-all py-5 lg:py-10`}
            onClick={(e) => {
              e.preventDefault();
              setCurIndex(index);
            }}
            key={index}
          >
            <div
              className={`h-1 ${
                index === curIndex % valuePropAndCaptionDict.length
                  ? "bg-blue-500"
                  : "bg-gray-200"
              } transition-colors`}
            ></div>
          </button>
        ))}
      <div className="flex-1 w-full lg:hidden flex items-center justify-end">
        <button
          onClick={(e) => {
            e.preventDefault();
            setCurIndex(curIndex + 1);
          }}
          className="py-5"
        >
          <svg
            width="34"
            height="20"
            viewBox="0 0 34 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-gray-200"
          >
            <path d="M0 10L31 10" strokeWidth="3" />
            <path d="M23.0001 2L31 10.186L23 18" strokeWidth="3" />
          </svg>
        </button>
      </div>
    </div>
  );
}
