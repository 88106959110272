import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation, useParams } from "react-router-dom";
import AdPlanDetailBox from "../../components/customer/AdPlanDetailBox";
import PageHeader from "../../components/customer/PageHeader";
import { Link } from "react-router-dom";

function AdPlan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Buy Ads | Torium";
  }, []);

  const { adPlanId } = useParams();

  const { data: { getAdPlanById: adPlan } = {} } = useQuery(
    FETCH_AD_PLAN_BY_ID,
    {
      variables: { adPlanId },
    }
  );

  return (
    <div className="bg-gray-100 min-h-screen h-full w-full relative">
      <PageHeader title={"Purchase Confirmation"} />
      <div className="max-w-sm w-full pt-20 h-full mx-auto flex flex-col items-start justify-start px-4">
        {adPlan ? (
          <>
            <AdPlanDetailBox title="ID" value={adPlan.id} />
            <AdPlanDetailBox title="Business" value={adPlan.businessName} />
            <AdPlanDetailBox title="Plan type" value={adPlan.plan} />
            <AdPlanDetailBox
              title="Number of ads purchased"
              value={adPlan.numOfAdsPurchased}
            />
            <AdPlanDetailBox
              title="Amount paid"
              value={"CA$" + adPlan.netAmount / 100}
            />

            <div className="bg-white leading-tight text-xs rounded-xl py-2 px-3 mt-2">
              Getting right to work. If we haven't yet discussed how we're going
              to make your ad, please email us. Thank you!
            </div>
          </>
        ) : (
          <></>
        )}

        <Link
          className="text-white text-center mt-2 py-2 px-4 bg-black hover:bg-gray-800 transition-colors w-full rounded-lg text-xs"
          to="/"
        >
          Back to home
        </Link>

        <p className="mx-auto text-center text-xs leading-tight mt-2 mb-6">
          You can contact us at{" "}
          <a
            href="mailto:info@torium.ca"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
          >
            info@torium.ca
          </a>
          ,<br />
          <a
            href="https://twitter.com/T0R1UM"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
          >
            @T0R1UM on Twitter
          </a>
          , or
          <a
            href="https://tiktok.com/@t0rium"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
          >
            {" "}
            @t0rium on TikTok
          </a>
        </p>
      </div>
    </div>
  );
}

export const FETCH_AD_PLAN_BY_ID = gql`
  query getAdPlanById($adPlanId: String!) {
    getAdPlanById(adPlanId: $adPlanId) {
      id
      businessName
      plan
      numOfAdsPurchased
      netAmount
      status
      paymentConfirmedAt
    }
  }
`;

export default AdPlan;
