import React, { useState } from "react";

export default function InputTitle({
  title,
  errorMsg,
  clearBtnText,
  value,
  additionalBtnText,
  hint,
  blueBack,
  additionalBtnFunction,
  clearTextFunction,
  whiteBack,
}) {
  const [showHint, setShowHint] = useState(false);

  return (
    <>
      <div className="flex items-center justify-between w-full space-x-2 leading-tight">
        <h5 className="font-semibold text-black leading-tight flex-shrink-0 text-sm mt-3 mb-2">
          {title}
        </h5>
        {errorMsg && (
          <h5 className="text-red-500 truncate w-full font-medium leading-tight text-right text-sm">
            {errorMsg}
          </h5>
        )}
        {clearBtnText && value && (
          <button
            onClick={clearTextFunction}
            className="text-red-500 font-medium text-right text-sm"
          >
            {clearBtnText}
          </button>
        )}

        {additionalBtnText && (
          <button
            onClick={additionalBtnFunction}
            className="text-blue-500 font-medium text-right focus:outline-none focus:text-blue-300 text-sm"
          >
            {additionalBtnText}
          </button>
        )}

        {hint && (
          <button
            onClick={(e) => {
              // e.preventDefault();
              setShowHint(!showHint);
            }}
            className="text-white hover:text-gray-300 transition-colors pl-4 rounded-full text-sm"
          >
            <svg
              className="fill-current"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 5V7H11V5H9ZM9 9V15H11V9H9ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
              />
            </svg>
          </button>
        )}
      </div>

      {hint && showHint && (
        <button
          className="text-gray-300 p-4 sm:text-base bg-blue-dark hover:bg-red-900 w-full transition-colors text-sm rounded-xl shadow-lg-white motion-safe:animate-fadeInFast"
          onClick={(e) => {
            e.preventDefault();
            setShowHint(!showHint);
          }}
        >
          <p className="w-full leading-tight text-left">{hint}</p>
        </button>
      )}
    </>
  );
}
