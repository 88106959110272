import { gql, useMutation } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CardInputField from "../../components/customer/CardInputField";
import InputField from "../../components/customer/InputField";
import InputTitle from "../../components/customer/InputTitle";
import PageHeader from "../../components/customer/PageHeader";
import { useForm } from "../../util/hooks";

function BuyAds() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Buy Ads | Torium";
  }, []);

  const [errors, setErrors] = useState({});

  const { onChange, values, setValues } = useForm(buyAdCallback, {
    plan: "Basic",
    personName: "",
    businessName: "",
    email: "",
  });
  const stripe = useStripe();
  const elements = useElements();

  let navigate = useNavigate();

  const [loadingBuyAd, setLoadingBuyAd] = useState(false);

  const [processAdPlanPaymentResult] = useMutation(
    PROCESS_AD_PLAN_PAYMENT_RESULT,
    {
      update(proxy, { data: { processAdPlanPaymentResult: adPlan } }) {
        setErrors({});

        if (adPlan.status === 1) {
          navigate("/adPlan/" + adPlan.id);
        }
        setLoadingBuyAd(false);
      },
      onError(err) {
        setLoadingBuyAd(false);

        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
    }
  );
  const [isCardComplete, setIsCardComplete] = useState(false);

  const [buyAdPlan] = useMutation(BUY_AD_PLAN_MUTATION, {
    async update(_, { data: { buyAdPlan: adPlan } }) {
      setErrors({});

      const result = await stripe.confirmCardPayment(
        adPlan.paymentIntentClientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
      );

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        setErrors({
          ...errors,
          paymentError: result.error.message,
        });
        setLoadingBuyAd(false);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          processAdPlanPaymentResult({ variables: { adPlanId: adPlan.id } });
        } else {
          setErrors({
            ...errors,
            paymentError: "Invalid",
          });
          setLoadingBuyAd(false);
        }
      }
    },
    onError(err) {
      setLoadingBuyAd(false);

      console.log(err);
      setErrors({
        paymentError: isCardComplete ? null : "Required",
        ...err.graphQLErrors[0].extensions.errors,
      });
    },
    variables: values,
  });

  function buyAdCallback(event) {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoadingBuyAd(true);
    buyAdPlan();
  }
  const [cardElemIsFocused, setCardElemIsFocused] = useState(false);

  return (
    <div className="bg-gray-100 min-h-screen h-full w-full relative">
      <PageHeader title="Buy TikTok Ads" />
      <div className="max-w-sm w-full pt-20 h-full mx-auto flex flex-col items-start justify-start px-4">
        <div
          className={`flex-shrink-0 flex flex-col items-start justify-start w-full overflow-hidden`}
        >
          <InputTitle
            title={"Pick a plan"}
            errorMsg={errors && errors.adPlan}
          />

          <button
            onClick={(e) => {
              e.preventDefault();
              setValues({ ...values, plan: "Basic" });
            }}
            className={`${
              values.plan === "Basic"
                ? "border-2 border-blue-600 bg-blue-100"
                : "border-2 border-gray-200 hover:bg-blue-50 hover:border-blue-200"
            } w-full mb-1  transition-colors rounded-lg p-3 flex flex-col items-center justify-center space-y-1`}
          >
            <div className="flex items-center justify-between w-full leading-none">
              <p className="font-semibold">Basic</p>
              <p className="font-semibold">
                <span className="text-gray-600">CA$</span>47
              </p>
            </div>
            <div className="flex items-center justify-between w-full text-xs leading-none">
              <p className="text-gray-600">1 video</p>
              {/* <div className="rounded-full px-2 py-0.5 bg-green-600 text-white">
                Save $200
              </div> */}
            </div>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setValues({ ...values, plan: "Pro" });
            }}
            className={`${
              values.plan === "Pro"
                ? "border-2 border-blue-600 bg-blue-100"
                : "border-2 border-gray-200 hover:bg-blue-50 hover:border-blue-200"
            } w-full mb-1  transition-colors rounded-lg p-3 flex flex-col items-center justify-center space-y-1`}
          >
            <div className="flex items-center justify-between w-full leading-none">
              <p className="font-semibold">Pro</p>
              <p className="font-semibold">
                <span className="text-gray-600">CA$</span>200
              </p>
            </div>
            <div className="flex items-center justify-between w-full text-xs leading-none">
              <p className="text-gray-600">5 videos</p>
              <div className="rounded-full px-2 py-0.5 bg-green-600 text-white">
                Save $35
              </div>
            </div>
          </button>
        </div>

        <InputField
          title="Your name"
          disabled={loadingBuyAd}
          placeholder={"Sadaf Nadeem"}
          name="personName"
          value={values.personName}
          errorMsg={errors && errors.personName}
          onChangeFunction={onChange}
        />
        <InputField
          title="Business name"
          disabled={loadingBuyAd}
          placeholder={"Torium Films"}
          name="businessName"
          value={values.businessName}
          errorMsg={errors && errors.businessName}
          onChangeFunction={onChange}
        />
        <InputField
          title="Business email"
          disabled={loadingBuyAd}
          placeholder={"info@torium.ca"}
          name="email"
          value={values.email}
          errorMsg={errors && errors.email}
          onChangeFunction={onChange}
          type="email"
        />

        <CardInputField
          title="Payment info"
          cardElemIsFocused={cardElemIsFocused}
          setCardElemIsFocused={setCardElemIsFocused}
          error={errors && errors.paymentError}
          setIsCardComplete={setIsCardComplete}
          disabled={loadingBuyAd}
        />

        <button
          className="text-white text-center h-10 mt-4 py-2 px-4 bg-black hover:bg-gray-800 transition-colors w-full rounded-lg"
          onClick={buyAdCallback}
          disabled={loadingBuyAd}
        >
          {loadingBuyAd ? (
            <svg
              className="w-4 sm:w-3 animate-spin mx-auto"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.636 3.636A9 9 0 0119 10"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </svg>
          ) : (
            "Submit"
          )}
        </button>
        <p className="mx-auto px-4 text-center text-xs leading-tight mt-2 mb-6">
          By clicking submit, you agree to our{" "}
          <Link
            to="/terms"
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
          >
            Terms and Conditions
          </Link>{" "}
          and{" "}
          <Link
            to="/privacy"
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
          >
            Privacy Policy
          </Link>
        </p>
      </div>
    </div>
  );
}

export const PROCESS_AD_PLAN_PAYMENT_RESULT = gql`
  mutation processAdPlanPaymentResult($adPlanId: String!) {
    processAdPlanPaymentResult(adPlanId: $adPlanId) {
      id
      status
    }
  }
`;
export const BUY_AD_PLAN_MUTATION = gql`
  mutation buyAdPlan(
    $plan: String!
    $personName: String!
    $businessName: String!
    $email: String!
  ) {
    buyAdPlan(
      plan: $plan
      personName: $personName
      businessName: $businessName
      email: $email
    ) {
      id
      paymentIntentClientSecret
    }
  }
`;
export default BuyAds;
