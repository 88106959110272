import React, { useEffect } from "react";
// import ParagraphCaption from "../../components/customer/ParagraphCaption";
import ReactGA from "react-ga";
import { Link, useLocation } from "react-router-dom";
import PageHeader from "../../components/customer/PageHeader";
import ParagraphTitle from "../../components/customer/ParagraphTitle";

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    document.title = "Terms | Torium";
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen h-full w-full relative">
      <PageHeader title="Terms and Conditions" />
      <div className="max-w-sm w-full pt-20 h-full mx-auto flex flex-col items-start justify-start px-4">
        <ParagraphTitle title="1. Contractual Relationship" />

        <p className="text-gray-800 leading-tight text-xs mb-1">
          These Terms and Conditions govern your use of Torium. Your access and
          use is also governed by our{" "}
          <Link
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
            to="/privacy"
          >
            Privacy Policy
          </Link>
          . By accessing or using Torium, you confirm your agreement to both.
          Torium may terminate these terms or the entire platform with respect
          to you, or generally, at any time for any reason.
        </p>

        <ParagraphTitle title="2. Arbitration Agreement" />
        <p className="text-gray-800 leading-tight text-xs mb-1">
          By agreeing to the Torium Terms, you agree that you will resolve any
          disputes you may have against Torium in arbitration.
        </p>

        <ParagraphTitle title="3. The Torium Platform" />
        <p className="text-gray-800 leading-tight text-xs mb-1">
          Torium creates short films. We also make TikTok ads for businesses.
        </p>

        <ParagraphTitle title="5. Disclaimers" />
        <p className="text-gray-800 leading-tight text-xs mb-1">
          We may occasionally update these terms. If we make significant
          changes, we will notify you through email. Use of Torium after an
          update to the Terms is equivalent to agreement.
        </p>

        <ParagraphTitle title="6. Credits" />
        <p className="text-gray-800 leading-tight text-xs ">
          The design of Torium's website is based on{" "}
          <a
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
            href="https://dribbble.com/shots/15886164-Voice-morphing-interface-Natural"
            target="_blank"
            rel="noreferrer"
          >
            Gleb Kuznetsov's amazing work at Natural
          </a>
          .
        </p>

        {/* <p className="text-gray-800 leading-tight text-xs mb-1">
          If you have any questions regarding privacy, please read our{" "}
          <Link
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
            to="/privacy"
          >
            Privacy Policy
          </Link>
          .
        </p> */}
        <Link
          className="text-white text-center mt-2 py-2 px-4 bg-black hover:bg-gray-800 transition-colors w-full rounded-lg text-xs"
          to="/"
        >
          Back to home
        </Link>
        <p className="mx-auto text-center text-xs leading-tight mt-2 mb-6">
          You can contact us at{" "}
          <a
            href="mailto:info@torium.ca"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
          >
            info@torium.ca
          </a>{" "}
          or
          <br />
          <a
            href="https://twitter.com/T0R1UM"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:text-blue-600 transition-colors font-semibold"
          >
            {" "}
            @T0R1UM on Twitter
          </a>
        </p>
      </div>
    </div>
  );
}
