import { CardElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import InputTitle from "./InputTitle";

// const CARD_ELEMENT_OPTIONS = {
//   hidePostalCode: true,

//   style: {
//     base: {
//       color: "#000",
//       fontFamily: '"Lexend Exa", Lexend Exa, sans-serif',
//       fontSmoothing: "antialiased",
//       fontSize: "1.25rem",
//       fontWeight: "500",
//       "::placeholder": {
//         color: "#9ca3af",
//       },
//     },
//     invalid: {
//       color: "#fa755a",
//       iconColor: "#fa755a",
//     },
//   },
// };
export default function CardInputField({
  cardElemIsFocused,

  setCardElemIsFocused,
  setIsCardComplete,

  error,
  title,
}) {
  const [inputCompletionPercentage, setInputCompletionPercentage] = useState(0);

  return (
    <div
      className={`flex-shrink-0 flex flex-col items-start justify-start w-full overflow-hidden`}
    >
      <InputTitle title={title} errorMsg={error} />

      {/* <div className="w-full flex flex-col items-start justify-start z-20"> */}
      <div className="h-6 w-full overflow-hidden z-20">
        <CardElement
          options={{
            hidePostalCode: true,

            style: {
              base: {
                color: "black",
                fontFamily: "Lexend Exa, Lexend Exa, sans-serif",
                fontSmoothing: "antialiased",
                fontSize: "1.5rem",
                fontWeight: "500",

                "::placeholder": {
                  color: "#9ca3af",
                },
                iconColor: "#9ca3af",
              },
              complete: {
                color: "black",
                fontFamily: "Lexend Exa, Lexend Exa, sans-serif",
                fontSmoothing: "antialiased",
                fontSize: "1.5rem",
                fontWeight: "500",

                "::placeholder": {
                  color: "#9ca3af",
                },
                iconColor: "black",
              },
              invalid: {
                color: "#dc2626",
                iconColor: "#dc2626",
              },
            },
          }}
          id="paymentInfo"
          onFocus={() => {
            if (inputCompletionPercentage < 10) {
              setInputCompletionPercentage(10);
            }
            setCardElemIsFocused(true);
          }}
          onBlur={() => {
            if (inputCompletionPercentage === 10) {
              setInputCompletionPercentage(0);
            }
            setCardElemIsFocused(false);
          }}
          onChange={(e) => {
            if (e.complete) {
              setIsCardComplete(true);

              setInputCompletionPercentage(100);

              // if (error === "Required") {
              //   setHideErrorMsg(true);
              // }
            } else if (e.brand !== "unknown") {
              setInputCompletionPercentage(40);
            } else {
              setInputCompletionPercentage(10);
            }

            if (!e.complete) {
              setIsCardComplete(false);
              // setHideErrorMsg(false);
            }
          }}
          //   className="w-full"
        />
      </div>
    </div>
  );
}
