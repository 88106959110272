import React from "react";
// import { Link } from "react-router-dom";

export default function Footer({ curIndex, setCurIndex, props }) {
  return (
    <div className="relative lg:w-full">
      <button
        className="backdrop-blur-lg focus:outline-none text-xl lg:flex hidden bg-white group transition-all items-center justify-center p-6 absolute left-0 top-0 -mt-16 z-10"
        onClick={(e) => {
          e.preventDefault();
          setCurIndex(curIndex + 1);
        }}
      >
        <svg
          className="h-4 group-hover:stroke-blue-500 stroke-gray-200 transition-colors"
          viewBox="0 0 45 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 13H41.5" strokeWidth="4" />
          <path d="M31.5001 2L42 13L31.5 23.5" strokeWidth="4" />
        </svg>
      </button>
      <div className="backdrop-blur-lg relative w-screen lg:w-auto bg-white/30 py-4 px-4 lg:px-24 flex items-center justify-start space-x-4 lg:space-x-24 h-24">
        <div className="flex items-start justify-start space-y-2 flex-col">
          <h2 className="text-blue-900 text-sm leading-tight">Contact</h2>
          <div className="flex flex-col items-start justify-start">
            <p className="text-gray-500 text-xs leading-tight whitespace-nowrap">
              Email:{" "}
              <a
                href="mailto:info@torium.ca"
                target="_blank"
                className="underline hover:text-gray-200 transition-colors"
                rel="noreferrer"
              >
                info@torium.ca
              </a>
            </p>
            <p className="text-gray-500 text-xs leading-tight whitespace-nowrap">
              Twitter:{" "}
              <a
                href="https://twitter.com/T0R1UM"
                target="_blank"
                className="underline hover:text-gray-200 transition-colors"
                rel="noreferrer"
              >
                @T0R1UM
              </a>
            </p>
          </div>
        </div>

        <div className="flex items-start justify-start space-y-2 flex-col">
          <h2 className="text-blue-900 text-sm leading-tight">Work</h2>

          <div className="flex flex-col items-start justify-start">
            <p className="text-gray-500 text-xs leading-tight whitespace-nowrap">
              TikTok:{" "}
              <a
                href="https://www.tiktok.com/@t0rium"
                target="_blank"
                className="underline hover:text-gray-200 transition-colors"
                rel="noreferrer"
              >
                @t0rium
              </a>
            </p>
            <p className="text-gray-500 text-xs leading-tight whitespace-nowrap">
              Instagram:{" "}
              <a
                href="https://www.instagram.com/toriumfilms/"
                target="_blank"
                className="underline hover:text-gray-200 transition-colors"
                rel="noreferrer"
              >
                @toriumfilms
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
