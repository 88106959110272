import React, { useState } from "react";
// import ParagraphTitle from "./ParagraphTitle";
import InputTitle from "./InputTitle";

export default function InputField({
  title,
  errorMsg,
  placeholder,
  name,
  onChangeFunction,
  value,
  type,

  disabled,
  keepAutoCompleteOff,

  additionalBtnText,

  clearBtnText,

  maxLength,

  hint,

  isFirst,
}) {
  const [secureTextEntry, setSecureTextEntry] = useState(
    type === "password" ? true : false
  );

  return (
    <div
      className={`${
        isFirst ? "sm:pt-24 pt-4" : ""
      } flex-shrink-0 flex flex-col items-start justify-start w-full overflow-hidden`}
    >
      {(title ||
        errorMsg ||
        clearBtnText ||
        value ||
        additionalBtnText ||
        hint) && (
        <InputTitle
          title={title}
          errorMsg={errorMsg}
          clearBtnText={clearBtnText}
          value={value}
          additionalBtnText={additionalBtnText}
          hint={hint}
        />
      )}

      <div className="w-full flex items-center justify-center h-full">
        <input
          className={`${
            disabled ? "text-gray-800" : "text-black"
          } transition-colors text-2xl sm:text-3xl bg-transparent w-full font-medium focus:outline-none rounded`}
          placeholder={placeholder}
          error={errorMsg ? "true" : "false"}
          name={name}
          onChange={onChangeFunction}
          value={value}
          type={
            type
              ? type === "password"
                ? secureTextEntry
                  ? "password"
                  : "text"
                : type
              : "text"
          }
          autoComplete={keepAutoCompleteOff ? "new-password" : "on"}
          disabled={disabled ? true : false}
          maxLength={maxLength ? maxLength : "524288"}
        />

        {type === "password" && !disabled && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setSecureTextEntry(!secureTextEntry);
            }}
            className="hover:bg-blue-950 pl-2 rounded"
          >
            {secureTextEntry ? (
              <svg
                className="w-5"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 8C1.73 3.61 6 .5 11 .5S20.27 3.61 22 8c-1.73 4.39-6 7.5-11 7.5S1.73 12.39 0 8zm19.82 0A9.77 9.77 0 0011 2.5 9.77 9.77 0 002.18 8 9.76 9.76 0 0011 13.5c3.8 0 7.17-2.13 8.82-5.5zM11 5.5a2.5 2.5 0 010 5 2.501 2.501 0 010-5zM6.5 8c0-2.48 2.02-4.5 4.5-4.5s4.5 2.02 4.5 4.5-2.02 4.5-4.5 4.5S6.5 10.48 6.5 8z"
                  fill="#000"
                />
              </svg>
            ) : (
              <svg
                className="w-5"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.69 4.1L1.01 1.42 2.42 0l17.73 17.74-1.41 1.41-3.42-3.42c-1.34.53-2.8.82-4.32.82-5 0-9.27-3.11-11-7.5.77-1.97 2.06-3.67 3.69-4.95zM11 3.55a9.77 9.77 0 018.82 5.5 9.646 9.646 0 01-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53-1.73-4.39-6-7.5-11-7.5-1.27 0-2.49.2-3.64.57l1.65 1.65c.65-.13 1.31-.22 1.99-.22zM9.93 4.69L12 6.76c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07.01-2.48-2.01-4.49-4.49-4.49-.37 0-.72.05-1.07.14zM8.51 8.92l2.61 2.61c-.04.01-.08.02-.12.02a2.5 2.5 0 01-2.5-2.5c0-.025.002-.045.005-.065a.492.492 0 00.005-.065zM6.86 7.27L5.11 5.52c-1.21.92-2.23 2.1-2.93 3.53a9.77 9.77 0 008.82 5.5c.95 0 1.87-.14 2.75-.38l-.98-.98A4.507 4.507 0 016.5 9.05c0-.63.13-1.23.36-1.78z"
                  fill="#000"
                />
              </svg>
            )}
          </button>
        )}
      </div>
    </div>
  );
}
